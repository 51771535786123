import axios from "axios";
import React, { useEffect, useState } from "react";
import * as qs from "qs";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  async function handleCheckboxChange(e) {
    setIsChecked(e.target.checked);
  }

  async function login() {
    setLoginDisabled(true);
    if (email === "" || password === "" || isChecked === false) {
      alert("Please fill all the fields");
      setLoginDisabled(false);
      return;
    }

    let data = qs.stringify({
      token:
        "eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6Imt1c2hhZ3JhQGxldmVsLmdhbWUifQ.tv8W5DmhWzst_YH_oftB861ds_vTYa4NpYWd-WJBsAs",
      email: email,
      password: password,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://leveladmin.levelsupermind.com/cmakers/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    console.log("here");

    axios(config)
      .then((response) => {
        if (response?.data?.status == false) {
          alert("Invalid credentials");
          setLoginDisabled(false);
          return;
        }
        console.log(response);
        Cookies.set("userData", response?.data?.user, { expires: 31 });
        Cookies.set("token", response?.data?.token, { expires: 31 });
        window.location.href = "/home";
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  }

  async function checkIfLoggedIn() {
    let token = Cookies.get("token");
    if (token) {
      window.location.href = "/home";
    }
  }

  useEffect(() => {
    checkIfLoggedIn();
  });

  return (
    <div className="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-60 w-auto"
          src="https://res.cloudinary.com/di1qrjnz0/image/upload/v1655903926/images/gimp_og2hmn.png"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div className="text-sm">
              {/* <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Forgot password?
                </a> */}
            </div>
          </div>
          <div className="mt-2">
            <input
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="text-center text-sm text-gray-500 mt-10">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-indigo-600"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className="ml-2 font-semibold leading-6 ">
              <div className="bg-gray-100 p-4 rounded-lg">
                <p className="text-lg font-bold mb-4">I Agree.</p>
                <p className="text-gray-600 mb-2">
                  By creating an account and clicking “I Agree,” you, as a
                  reseller, hereby confirm and agree to the following:
                </p>
                <ol className="list-decimal list-inside ml-4 text-gray-600">
                  <li>
                    Acknowledge that you have read, understood, and agree to
                    comply with our{" "}
                    <a
                      target="_blank"
                      href="https://level.game/terms-and-conditions"
                      className="text-purple-500"
                    >
                      <u>Terms and Conditions </u>
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      href="https://level.game/privacy-policy"
                      className="text-purple-500"
                    >
                      <u>Privacy & Cookie Policy</u>
                    </a>
                  </li>
                  <li>
                    Commit to selling products below the Manufacturer's
                    Recommended Price (MRP) as stipulated in our reseller
                    guidelines.
                  </li>
                </ol>
              </div>
            </span>
          </label>
        </div>
        <div>
          <button
            onClick={login}
            disabled={loginDisabled}
            className="mt-5 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
