import axios from "axios";
import React, { useEffect, useState } from "react";
import * as qs from "qs";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [couponData, setCouponData] = useState();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [monthlyQuantity, setMonthlyQuantity] = useState(0);
  const [yearlyQuantity, setYearlyQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentEmail, setPaymentEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");

  async function getUserData() {
    var user_token = Cookies.get("token");

    let data = qs.stringify({
      token: user_token,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://leveladmin.levelsupermind.com/cmakers/subscriptions",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // console.log(response.data[0].email);
        setUserEmail(response.data[0].email);
        var data = response.data;
        setCouponData(data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function startPayment() {
    var user_token = Cookies.get("token");

    if (yearlyQuantity == 0) {
      toast.error("Please enter quantity");
      return;
    }

    if (paymentEmail == "") {
      toast.error("Please enter email");
      return;
    }

    var monthlySub = monthlyQuantity * 99;
    var yearlySub = yearlyQuantity * 999;

    var total = monthlySub + yearlySub;

    var get_url = await axios.get(
      "https://leveladmin.levelsupermind.com/cmakers/payment/phonepe?user_id=" +
        user_token +
        "&monthly_count=" +
        monthlyQuantity +
        "&yearly_count=" +
        yearlyQuantity
    );
    console.log(get_url.data);

    try {
      const url_data = new URL(get_url.data);
      window.location.href = url_data;
    } catch (error) {
      toast.error("Something went wrong");
      return;
    }
  }

  useEffect(() => {
    getUserData();
    getList();
  }, []);

  async function addPremiumSubscription() {
    setIsLoading(true);
    var user_token = Cookies.get("token");
    let data = qs.stringify({
      token: user_token,
      email: email,
      phone: phone,
      subscription_type: subscriptionType,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://leveladmin.levelsupermind.com/cmakers/add/subscriptions",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Premium subscription added successfully");
        getUserData();
        getList(); //Devika communicated this to remove monthly and baaki usse puch lena
        setIsLoading(false);
        setEmail("");
        setPhone("");
      })
      .catch((error) => {
        toast.error("No user found with this email or phone number");
        console.log(error);
      });
  }

  async function getList() {
    // setIsLoading(true);
    var user_token = Cookies.get("token");
    let data = qs.stringify({
      token: user_token,
      email: email,
      phone: phone,
      subscription_type: subscriptionType,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://leveladmin.levelsupermind.com/cmakers/get/all/subscriptions",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function totalPricing() {
    var TotalmonthlyQuantity = monthlyQuantity * 99;
    var TotalyearlyQuantity = yearlyQuantity * 999;
    var TotalPrice = TotalmonthlyQuantity + TotalyearlyQuantity;
    setTotalPrice(TotalPrice);
  }

  useEffect(() => {
    totalPricing();
  }, [monthlyQuantity, yearlyQuantity]);

  return (
    <>
      {/* Navbar */}
      <ToastContainer />

      <nav className="bg-gray-800">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="h-8 w-auto"
                  src="https://level.game/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.ccf455d6.webp&w=2048&q=75"
                  alt="Level Supermind"
                ></img>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  <a
                    href="#"
                    className="bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium"
                    aria-current="page"
                  >
                    Dashboard
                  </a>
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="relative ml-3">
                <div>
                  <button
                    type="button"
                    className="relative flex rounded-full bg-gray-800 text-sm focus:ring-2 text-white "
                    id="user-menu-button"
                    onClick={() => {
                      Cookies.remove("token");
                      Cookies.remove("userData");
                      window.location.href = "/";
                    }}
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Navbar ends here */}

      {/* All change makers data starts here */}
      <div className="flex mt-2 items-center justify-center">
        <div className="flex space-x-8">
          <div className="border rounded-lg p-6 max-w-sm flex flex-col justify-between">
            <div>
              <h2 className="text-2xl font-bold mb-4">Yearly Coupons Left</h2>
              <p className="mb-4 text-sm">
                You have{" "}
                <b>
                  <u>{couponData?.yearly_subscription_remaining}</u>
                </b>{" "}
                yearly coupons left, you have used{" "}
                {couponData?.yearly_subscription_used} till now
              </p>
            </div>
            {/* Buy More Button */}
            <button
              onClick={() => {
                setShowPayment(!showPayment);
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded self-end"
            >
              Buy More
            </button>
          </div>
        </div>
        {userEmail == "supriya@antarlakshya.com" ||
        userEmail == "anushask06@gmail.com" ||
        userEmail == "supriya@antarlakshya.com" ? (
          <div className="flex space-x-8">
            <div className="border rounded-lg p-6 max-w-sm flex flex-col justify-between">
              <div>
                <h2 className="text-2xl font-bold mb-4">
                  Monthly Coupons Left
                </h2>
                <p className="mb-4 text-sm">
                  You have{" "}
                  <b>
                    <u>{couponData?.monthly_subscription_remaining}</u>
                  </b>{" "}
                  monthly coupons left, you have used{" "}
                  {couponData?.monthly_subscription_used} till now
                </p>
              </div>
              {/* Buy More Button */}
              <button
                onClick={() => {
                  setShowPayment(!showPayment);
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded self-end"
              >
                Buy More
              </button>
            </div>
          </div>
        ) : null}
      </div>

      {/* Premium adding starts here
       */}
      {showPayment ? (
        <>
          <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
            <a href="#" className="text-2xl font-bold text-gray-800">
              Level ChangeMaker
            </a>
            <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
              <div className="relative">
                <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
                  <li className="flex items-center space-x-3 text-left sm:space-x-4">
                    <a
                      className="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </a>
                    <span className="font-semibold text-gray-900">Choose</span>
                  </li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  <li className="flex items-center space-x-3 text-left sm:space-x-4">
                    <a
                      className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2"
                      href="#"
                    >
                      2
                    </a>
                    <span className="font-semibold text-gray-900">Payment</span>
                  </li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  <li className="flex items-center space-x-3 text-left sm:space-x-4">
                    <a
                      className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white"
                      href="#"
                    >
                      3
                    </a>
                    <span className="font-semibold text-gray-500">
                      Completed
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
            <div className="px-4 pt-8">
              <p className="text-xl font-medium">Order Summary</p>
              <p className="text-gray-400">
                Check your items. And select a suitable plan.
              </p>
              <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
                {userEmail == "supriya@antarlakshya.com" ||
                userEmail == "anushask06@gmail.com" ||
                userEmail == "supriya@antarlakshya.com" ? (
                  <div className="flex flex-col rounded-lg bg-white sm:flex-row">
                    <img
                      className="m-2 h-24 w-28 rounded-md border object-cover object-center"
                      src="https://res.cloudinary.com/di1qrjnz0/image/upload/v1697280770/Level_Logo_NEW_vq8jkv.png"
                      alt=""
                    />

                    <div className="flex w-full flex-col px-4 py-4">
                      <span className="font-semibold">
                        Level Monthly Premium Subscription
                      </span>
                      <span className="float-right text-gray-400">
                        Min Quantity - 5
                      </span>
                      <p className="text-lg font-bold">₹99</p>
                      <input
                        className="float-right mt-2 border-2 border-gray-200 rounded-md"
                        placeholder="Enter Quantity"
                        type="number"
                        onChange={(e) => setMonthlyQuantity(e.target.value)}
                      ></input>
                    </div>
                  </div>
                ) : null}

                <div className="flex flex-col rounded-lg bg-white sm:flex-row">
                  <img
                    className="m-2 h-24 w-28 rounded-md border object-cover object-center"
                    src="https://res.cloudinary.com/di1qrjnz0/image/upload/v1697280770/Level_Logo_NEW_vq8jkv.png"
                    alt=""
                  />
                  <div className="flex w-full flex-col px-4 py-4">
                    <span className="font-semibold">
                      Level Premium Yearly Subscription
                    </span>
                    <span className="float-right text-gray-400">
                      Min Quantity - 1
                    </span>
                    <p className="mt-auto text-lg font-bold">₹999/-</p>
                    <p className="mt-auto font-semibold text-sm">
                      Maximum retail price = ₹1548/-
                    </p>

                    <input
                      type="number"
                      className="float-right mt-2 border-2 border-gray-200 rounded-md"
                      placeholder="Enter Quantity"
                      onChange={(e) => setYearlyQuantity(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
              <p className="text-xl font-medium">Payment Details</p>
              <p className="text-gray-400">
                Complete your order by providing your payment details.
              </p>
              <div className="">
                <label
                  htmlFor="email"
                  className="mt-4 mb-2 block text-sm font-medium"
                >
                  Your Email
                </label>
                <div className="relative">
                  <input
                    type="text"
                    onChange={(e) => setPaymentEmail(e.target.value)}
                    id="email"
                    name="email"
                    className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="your.email@gmail.com"
                  />
                  <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>
                  </div>
                </div>

                {/* Total */}
                <div className="mt-6 border-t border-b py-2">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      Yearly Sub - {yearlyQuantity}
                    </p>
                    <p className="font-semibold text-gray-900">
                      ₹ {yearlyQuantity * 999}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      Monthly Sub - {monthlyQuantity}
                    </p>
                    <p className="font-semibold text-gray-900">
                      ₹ {monthlyQuantity * 99}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Total</p>
                  <p className="text-2xl font-semibold text-gray-900">
                    ₹ {totalPrice}
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  startPayment();
                }}
                className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
              >
                Place Order
              </button>
            </div>
          </div>
        </>
      ) : null}

      <div className="mx-5 my-5">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Provide premium subscription
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Learn how to{" "}
              <a className="text-blue-600 font-bold" href="#">
                add premium subscription
              </a>
            </p>

            <p className="mt-1 text-sm leading-6 text-gray-600">
              Learn
              <a
                className="text-blue-600 font-bold"
                href="https://v3.cdn.level.game/appusage.pdf"
              >
                {" "}
                how to use the app
              </a>
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your Client's Registered Email
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your Client's Registered Phone Number
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    onChange={(e) => setPhone(e.target.value)}
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Subscription Type
                </label>
                <div className="mt-2">
                  <select
                    id="country"
                    name="country"
                    onChange={(e) => setSubscriptionType(e.target.value)}
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option defaultChecked>--Choose One--</option>
                    <option value="YEARLY">Yearly</option>
                    {userEmail == "supriya@antarlakshya.com" ||
                    userEmail == "anushask06@gmail.com" ||
                    userEmail == "supriya@antarlakshya.com" ? (
                      <option value="MONTHLY">Monthly</option>
                    ) : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              {isLoading ? (
                <button
                  className="rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600 cursor-not-allowed"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <button
                  onClick={addPremiumSubscription}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Grant Premium
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex min-h-screen flex-col px-6 py-12 lg:px-8">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="border-b font-medium dark:border-neutral-500">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        id
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Premium Ends on{" "}
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Subscription Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => (
                      <tr
                        key={index}
                        className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600"
                      >
                        <td className="whitespace-nowrap px-6 py-4 font-medium">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item.name}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item.email}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item.premium_till}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item.subscription_type}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
